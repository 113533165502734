import React from "react"
import { Link } from "gatsby"

import Layout from "src/components/layout"
import SEO from "src/components/seo"
import PageTitle from "src/components/page-title"

import CacFormula from "../../images/customer-acquisition-cost/customer-acquisition-cost-formula.png"
import ClvFormula from "../../images/customer-acquisition-cost/customer-lifetime-value-formula.png"
import starbucksLtv from "../../images/customer-acquisition-cost/starbucks-ltv.png"
import freeTrialLtv from "../../images/customer-acquisition-cost/free-trial-cac.png"
import newVsReturning from "../../images/customer-acquisition-cost/new_vs_returning.png"
import churnMetricsLtv from "../../images/customer-acquisition-cost/churnmetrics_ltv.png"
import frankBank from "../../images/customer-acquisition-cost/frank_ocbc_bank.png"

export default class CustomerAcquisitionCalculator extends React.Component {
  state = {
    newCustomers: 0,
    averageRevPerMonth: 0,
    advertising: 0,
    staff: 0,
    salary: 0,
    software: 0,
    other: 0,
    showResults: false,
    costOfCustomerAcquisition: 0,
    paybackPeriod: 0,
  }
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    this.setState({ showResults: true })
    console.log(this.state.showResults)

    var customers = parseInt(this.state.newCustomers) || 1
    var advertising = parseInt(this.state.advertising)
    var staff = parseInt(this.state.staff)
    var salary = parseInt(this.state.salary)
    var software = parseInt(this.state.software)
    var other = parseInt(this.state.other)
    var rev = parseInt(this.state.averageRevPerMonth)

    var totalSalary = staff * salary
    console.log(totalSalary)

    var totalSpend = totalSalary + advertising + software + other
    console.log(totalSpend)
    var cac = parseInt(totalSpend / customers)

    this.setState({ costOfCustomerAcquisition: cac })
    console.log(this.state.costOfCustomerAcquisition)

    var monthsPayback = parseInt(cac / rev)
    this.setState({ paybackPeriod: monthsPayback })
    console.log(this.state.paybackPeriod)
  }

  render() {
    return (
      <Layout>
        <SEO title="Customer Acquisition Cost (CAC) Calculator" description="A simple customer acquisition cost calculator for any type of business. Get a free and easy calculation of CAC based on your own business numbers."/>
        <PageTitle
          preheader="SaaS Calculator"
          title="Customer Acquisition Cost (CAC) Calculator"
          subtitle="Tracking customer acquisition cost accurately is a difficult business, especially if you have a blend of marketing channels.
          Use our simple calculator to get an estimate based on your actual data."
        ></PageTitle>

        <form
          class="space-y-8 divide-y divide-gray-200 pb-16"
          onSubmit={this.handleSubmit}
        >
          <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div>
              <div>
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Your Customer Acquisition Cost Inputs
                </h3>
                <p class="mt-1 max-w-2xl text-sm text-gray-500">
                  We use this to calculate the output of your efforts. No
                  information is saved.
                </p>
              </div>

              <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    for="first_name"
                    class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    How many customers did you get in the last month?
                  </label>
                  <div class="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="number"
                      name="newCustomers"
                      id="newCustomers"
                      class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    for="first_name"
                    class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    What's your average revenue per month from the new customers
                    (per customer)?
                  </label>
                  <div class="max-w-lg flex rounded-md shadow-sm">
                    <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                      $
                    </span>
                    <input
                      type="number"
                      name="averageRevPerMonth"
                      id="averageRevPerMonth"
                      class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
              <div>
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Your Customer Acquisition Inputs
                </h3>
                <p class="mt-1 max-w-2xl text-sm text-gray-500">
                  We use this to calculate your investment in acquisition. No
                  information is saved.
                </p>
              </div>
              <div class="space-y-6 sm:space-y-5">
                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    for="last_name"
                    class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    How much did you spend on direct advertising in the last
                    month?
                  </label>
                  <div class="max-w-lg flex rounded-md shadow-sm">
                    <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                      $
                    </span>
                    <input
                      type="number"
                      name="advertising"
                      id="advertising"
                      class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    for="last_name"
                    class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    How many people work in your marketing & sales team?
                  </label>
                  <div class="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="number"
                      name="staff"
                      id="staff"
                      class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    for="last_name"
                    class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    What's the average salary for a person working in your
                    marketing & sales team?
                  </label>
                  <div class="max-w-lg flex rounded-md shadow-sm">
                    <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                      $
                    </span>
                    <input
                      type="number"
                      name="salary"
                      id="salary"
                      class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    for="number"
                    class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 row-span-2"
                  >
                    What did you spend on marketing & sales software in the last
                    month?
                  </label>
                  <div class="max-w-lg flex rounded-md shadow-sm">
                    <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                      $
                    </span>
                    <input
                      type="number"
                      name="software"
                      id="software"
                      class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div></div>
                  <div class="mt-1 sm:mt-0">
                    <p class="mt-2 text-sm text-gray-500">
                      Include all non-publisher spend: CRM, social media
                      management, mailing list software, etc.
                    </p>
                  </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    for="last_name"
                    class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 row-span-2"
                  >
                    Any other customer acquisition costs?
                  </label>
                  <div class="max-w-lg flex rounded-md shadow-sm">
                    <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                      $
                    </span>
                    <input
                      type="number"
                      name="other"
                      id="other"
                      class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div></div>
                  <div class="mt-1 sm:mt-0">
                    <p class="mt-2 text-sm text-gray-500">
                      Include other spend such as agency fees, but do not
                      include billed publisher spend if already included in
                      question 3.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="pt-5">
            <div class="flex justify-end">
              <button
                type="submit"
                class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-oOrange hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-light"
              >
                Calculate
              </button>
            </div>
          </div>
        </form>
        <div className={this.state.showResults ? "block px-6 py-6 bg-oOrange rounded-lg mb-16 md:py-12 md:px-12 lg:py-16 lg:px-16 lg:w-2/3 m-auto text-white" : "hidden"}>
          <h2>Your results</h2>
          <h3>Your average Cost of Customer Acquisition is... {"$" + this.state.costOfCustomerAcquisition}</h3>
          <h3> Your payback period is {this.state.paybackPeriod + " "} months</h3>
        </div>
        <div class="py-6">
          <h2 id="how-does-customer-acquisition-cost-cac-work-">
            How does customer acquisition cost (CAC) work?
          </h2>
          <p class="italic">
            A look at some of the principles behind CAC, CLV, LTV and all those
            other acronyms...
          </p>
          <p>
            We can all agree that there are a lot of metrics to measure running
            ANY business.
          </p>
          <p>
            But listen, customer acquisition cost (from here, &#39;CAC,&#39;
            because let&#39;s face it, it&#39;s a mouthful...) is one of the
            most critical metrics out there.
          </p>
          <p>Here&#39;s the bottom line:</p>
          <p>Fail to understand it, and your business will sink.</p>
          <p>
            Grasp it and work with it... And you&#39;ll be on a fast-track to
            success.
          </p>
          <p>
            The CAC calculator above will give you a framework for working out a
            rough number for your business.
          </p>
          <p>
            If you want to understand how to do this for yourself (with more
            accuracy), read on.
          </p>
          <h2 id="why-pay-attention-to-cac-and-how-to-use-the-formula-">
            Why pay attention to CAC (and how to use the formula)
          </h2>
          <p>Business 101 says this:</p>
          <p>If you&#39;ve got a product, you need to sell it.</p>
          <p>
            That means getting customers – and usually, you&#39;ll have to pay
            to get those customers.
          </p>
          <p>
            That payment could take any form; it could be advertising on
            Facebook, buying ads through Google AdWords, sending out flyers to
            peoples&#39; letterboxes or standing on a street corner trying to
            sign them up.
          </p>
          <p>
            Whatever method you choose, you&#39;ll have to pay for it (yes...
            even the last one – it&#39;s a time cost!).
          </p>
          <p>
            The AMOUNT you&#39;ll have to pay, at the most basic level, is your
            CAC. Spend $100 on Facebook to get your first customer? Your CAC is
            $100. Spend $100 on PPC and $50 on mailed flyers and end up with two
            customers? Your CAC is $75 ($150 / two customers).
          </p>
          <p>
            This simple explanation illustrates the customer acquisition cost
            formula, which looks like this:
          </p>
          <p>
            <img
              src={CacFormula}
              alt="customer acquisition cost formula illustration"
            />
          </p>
          <p>
            In our calculator above, you&#39;ll see that we&#39;ve asked you to
            sum most of the core inputs into the CAC formula, but you could easily
            split out CAC by channel by dividing the total amount you spent on a
            single channel by the number of customers it produced.
          </p>
          <h2 id="why-customer-lifetime-value-ltv-is-important">
            Why customer lifetime value (LTV) is important
          </h2>
          <p>
            To build a viable business, your revenue from a customer MUST exceed
            the money that you spent to acquire that customer.
          </p>
          <p>
            That&#39;s why you should pair CAC with LTV (and why we did above) –
            it&#39;s a measurement of profitability.
          </p>
          <p>
            For many businesses, this might be very simple – if you sell cars,
            for instance, people will buy very infrequently and so the total
            value of that customer is probably just the profit on one car.
          </p>
          <h4 id="example-1-an-auto-dealership">
            Example 1: An auto dealership
          </h4>
          <p>
            Let&#39;s imagine an auto dealership that sells ten cars a week, and
            never sees any repeat business.
          </p>
          <p>
            Each car costs $15,000, and the profit the dealer will make on the
            vehicle is $3,000 – giving her a gross profit of $30,000.
          </p>
          <p>
            However, to keep up that flow of car-buying customers, the dealer is
            spending $25,000 on newspaper ads every week.
          </p>
          <p>
            Dividing that $25,000 by the ten customers means her CAC is $2500.
          </p>
          <p>
            That&#39;s a thinner margin than we may have assumed on weekly
            revenues of $150,000 – because of the high CAC, her weekly profit is
            just $5000.
          </p>
          <p>Or $500 per unit.</p>
          <p>
            But for most businesses, people will buy more than once – either by
            visiting a store repeatedly or paying monthly on a credit card (as
            is the case for SaaS businesses).
          </p>
          <p>
            Now the CAC calculation becomes more complicated because we have to
            work out the value of the customer through their entire life (known
            as lifetime value or customer lifetime value... or LTV or CLTV).
          </p>
          <p>For SaaS businesses, LTV looks like this:</p>
          <p>
            <img
              src={ClvFormula}
              alt="customer acquisition cost calculator"
            />
          </p>
          <p>
            If you want more information on calculating LTV, we recommend the
            <a href="https://blog.chartmogul.com/wp-content/uploads/2015/04/ChartMogul-Ultimate-Guide-to-SaaS-Customer-LTV.pdf">
              ChartMogul Ultimate Guide to SaaS Customer Lifetime Value
            </a>
            .
          </p>
          <h4 id="example-2-a-saas-business">Example 2: A SaaS Business</h4>
          <p>
            Let&#39;s imagine a SaaS business which keeps its customers for 12
            months, on average.
          </p>
          <p>
            Now, every month, their customers pay them $100 on average, which
            means that over the lifetime of the customer, they will hand over an
            average of $1200.
          </p>
          <p>
            Now imagine the company spent $1000 on marketing and related
            expenses last month, and acquired five customers, making their CAC
            $200.
          </p>
          <p>
            Armed with these two figures (assuming the LTV doesn&#39;t change),
            we can infer that the profitability per customer is $1000.
          </p>
          <p>
            For other types of business, there may be better ways to work out
            the total value of a customer over their time with the company or
            product.
          </p>
          <h3 id="a-word-about-cac-in-retail">A word about CAC in retail</h3>
          <p>
            Retailers tend to use advanced methods for calculating CAC because
            customer lifespans tend to be longer and the average spend tends to
            be shorter. If you imagine the number of times you&#39;ll pop into
            Target during your life (no matter where the location) and how much
            you&#39;ll spend there, you&#39;ll see how figuring out this amount
            of money can be crazy difficult.
          </p>
          <p>
            In this
            <a href="https://blog.kissmetrics.com/wp-content/uploads/2011/08/calculating-ltv.pdf">
              fantastic PDF file,
            </a>
            Kissmetrics and Avinash Kaushik have broken down the LTV calculation
            using Starbucks as an example.
          </p>
          <p>
            <img
              src={starbucksLtv}
              alt="Starbucks ltv"
            />
          </p>
          <p>
            Credit:
            <a href="https://blog.kissmetrics.com/wp-content/uploads/2011/08/calculating-ltv.pdf">
              Kissmetrics
            </a>
          </p>
          <p>
            Spoiler – the average customer is worth a whopping $14k to Starbucks
            over the course of their life, but the methodology used to reach
            that figure is worth paying attention to, especially as there are
            several potential formulae at play.
          </p>
          <h2 id="cac-gotchas">CAC Gotchas</h2>
          <p>
            If you want to calculate CAC using more than the simple calculator
            above, there are a couple of gotchas you should know.
          </p>
          <p>
            First of all, CAC can have a LONG lead time. If you&#39;re selling
            cars, it could be three months between someone seeing your ads and
            finally buying the car. If you&#39;re looking at a month&#39;s worth
            of marketing expenditure and comparing it to that month of sales,
            you&#39;re doing something wrong.
          </p>
          <p>
            Secondly, there are a bunch of costs that traditional calculators
            and formulas leave out – we&#39;ve tried to include some in the
            calculator, but we won&#39;t have thought of all of them.
          </p>
          <p>
            To produce an accurate CAC you need to factor in associated costs
            such as staff time, subscriptions to products related to marketing
            (web hosting, for instance), and other hidden expenses such as
            discounts you&#39;ve offered in promotional materials or the support
            time that&#39;s gone into supporting somebody who&#39;s taken a free
            trial or test drive.
          </p>
          <p>
            For example, there are multiple considerations in calculating the
            cost of customer acquisition for customers that joined via a free
            trial:
          </p>
          <p>
            <img
              src={freeTrialLtv}
              alt="free trial customer acquisition cost"
            />
          </p>
          <p>Thirdly, you&#39;ll need to know your customers very well.</p>
          <p>
            This is a particular problem for bricks and mortar businesses, who
            don&#39;t always know when a customer first walked in and how many
            times they&#39;ll subsequently visit (see the Starbucks example
            above).
          </p>
          <p>
            You&#39;ll need to decide on how to treat repeat customers in your
            CAC calculations – we&#39;d typically err on the side of caution and
            discount them altogether. However, we&#39;ll cover this more in the
            next section...
          </p>
          <h3 id="what-about-repeat-customers-driven-by-the-same-marketing-spend-">
            What about repeat customers driven by the same marketing spend?
          </h3>
          <p>
            A common question when working with lifetime value is how to deal
            with customers that have been &#39;reactivated&#39; by marketing.
          </p>
          <p>
            For example, imagine a $100 ad for a bakery in a local newspaper
            results in two new customers, but it also prompts a customer who
            hasn&#39;t bought in the past two years to try the bakery again. How
            should we deal with this?
          </p>
          <p>
            Technically CAC is the cost to acquire a NEW customer – for the
            purists out there, the repeat customer should be ignored altogether.
          </p>
          <p>
            However, in the real world, that&#39;s not a fair calculation, and
            most business will apportion some of the ad spend as
            &#39;reactivation&#39; spend. So, perhaps the total bill of $100 for
            the advert is discounted by 10%, meaning the CAC is $45 rather than
            $50 (because the ad spent to acquire those new customers is now
            $90).
          </p>
          <p>
            The difficulty, especially offline, is knowing by how much to
            discount. Unless you know for sure that x% of the readership has
            already visited the bakery, it&#39;s nearly impossible to do – so
            you&#39;ll need to guess.
          </p>
          <p>
            Online, things are a little easier, because most decent analytics
            software (including Google Analytics) can determine whether a
            visitor has been to the site before.
          </p>
          <p>
            <img
              src={newVsReturning}
              alt="New vs returning"
            />
          </p>
          <p>Google Analytics New vs Returning Report</p>
          <p>
            So if a $100 Facebook advertising campaign yielded the same results,
            it would be reasonably easy to see that (for example) 20% of the
            traffic had already visited before and therefore only $80 of
            spending should be treated as &#39;new&#39; CAC spend (giving us a
            CAC of $40).
          </p>
          <h2 id="improving-cac-and-ltv">Improving CAC and LTV</h2>
          <p>
            Basic maths tells us that we can improve a business&#39;s metrics in
            two ways – reducing the cost of customer acquisition or improving
            the lifetime value of the customer.
          </p>
          <p>
            Improving CAC can be achieved by optimizing marketing spends,
            cutting down on wasteful channels, improving targeting, using more
            free or lower-cost distribution methods (e.g., viral content,
            partnerships or freemium offerings) or reducing the total
            hours-per-deal (to cut people costs).
          </p>
          <p>
            Online businesses can also improve CAC by increasing the site
            conversion rate, which can be far more efficient than optimizing
            marketing spends.
          </p>
          <p>
            For example, at a 5% conversion rate, 50 people will become
            customers for every 1000 visitors. If a visitor costs $3 on average,
            that&#39;s $3000 in marketing spend for 50 customers, which is a CAC
            of $60 per customer.
          </p>
          <p>
            However, at a 6% conversion rate, 60 people will become customers
            for every 1000 visitors. Suddenly, the CAC drops to $50, a 16% fall.
            That&#39;s a far better way to improve CAC than by cutting marketing
            spend by 16%.
          </p>
          <h4 id="ways-to-improve-cac">Ways to improve CAC</h4>
          <ol>
            <li>Improve marketing spend efficiency</li>
            <li>Increase product virality</li>
            <li>Reduce sales/marketing staff expenses</li>
            <li>Conversion optimization</li>
          </ol>
          <p>
            Improving lifetime value is a whole different ballgame – it&#39;s a
            metric that speaks to the quality of your relationship with the
            customer and how integral your business is to them.
          </p>
          <p>
            Increasing the &#39;life&#39; of the customer (also known as
            customer retention) is one way to tackle lifetime value improvement
            – stop a customer from churning so soon, and they&#39;ll spend more
            over their lifetime.
          </p>
          <p>
            However, the best businesses combine increased retention with
            increased spend – steadily upselling over time, so that they grow
            more valuable to the company as they progress through their time as
            a customer.
          </p>
          <p>
            <img
              src={churnMetricsLtv}
              alt="Churnmetrics ltv"
            />
          </p>
          <p>Credit: Churnmetrics</p>
          <p>
            If you signed up for a &#39;starter&#39; bank account as a teenager
            and signed a mortgage loan with the same bank as an adult 20 years
            later, you&#39;ve seen this in action. Teenagers are worth next to
            nothing as banking clients (as they have minimal deposits and no
            borrowing costs), but keeping that teenager around until they&#39;re
            ready to take out an $xxx,xxx loan with the bank is a smart move –
            which is why banks invest so much in stores like this to appeal to
            young adults:
          </p>
          <p>
            <img
              src={frankBank}
              alt="Frank ocbc bank"
            />
          </p>
          <p>Credit: FRANK by OCBC in Singapore</p>
          <h2 id="cac-ltv-the-magic-ratio">CAC:LTV: The Magic Ratio</h2>
          <p>
            In case we haven&#39;t labored the point enough, CAC needs to be
            measured against LTV to be useful – put together, they form a magic
            ratio which is useful as an internal metric and also as an indicator
            to evaluate the health of a business.
          </p>
          <p>
            As a rule, investors will often look for a CAC to LTV ratio of three
            as a healthy number for a business – acquiring a customer
            shouldn&#39;t cost you more than a third of the revenue that
            customer generates.
          </p>
          <p>
            At a number higher than three, there&#39;s probably room to grow, by
            investing in more marketing channels. At lower than three, margins
            are squeezed, and the total headroom for the business may be
            questioned.
          </p>
          <p>
            Armed with all this theory, it shouldn&#39;t be a stretch to
            calculate your own CAC and LTV, whether you use the calculator above
            or not.
          </p>
          <p>
            Hopefully, you can see that although nuance is required, CAC and LTV
            remain two of the most useful metrics businesses have.
          </p>
        </div>
      </Layout>
    )
  }
}
